import React, { useEffect, useState } from "react";
import "../venueCard.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import Axios from "../../../../Service/Instance";
import NoDataImage from "../../../../Assets/Images/no-data-image.jpg";
import LoadingImage from "../../../../Assets/Images/loading_Gif.gif";
import Card from "../../../CardSec/Card";
import Icons from "../../../Featured/Icons";
import SecondCard from "../../../CardSec/SecondCard";
import parse from "html-react-parser";
import "../venueCard.scss";
import "../../../StoreBanner/StoreBanner.scss";
import GalleryPreviewModal from "../../../../model/gallery-preview-modal/galleryPreviewModal";
import { Carousel } from "react-bootstrap";

export default function VenueCardDetails() {
  const [venue, setVenue] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const params = useParams();
  const [cardDetails, setCardDetails] = useState<any>([]);
  const [isActive, setIsActive] = useState(true);
  const location = useLocation();

  const [total, setTotal] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [showImagePreview, setShowImagePreview] = useState<any>(false);
  const [settingIndex, setSettingIndex] = useState<any>(0);
  const [showGalleryPreview, setShowGalleryPreview] = useState<any>(false);
  const [galleryIndex, setGalleryIndex] = useState<any>(0);
  const [sponsorList, setSponserList] = useState<any>([]);
  const [venueId, setVenueId] = useState<any>("");

  const getCurrentPageData = async () => {
    try {
      const VenueResponse = await Axios.get(
        `events?currentPage=${currentPage}&pageSize=${pageSize}&venueId=${venueId}`
      );
      setTotal(VenueResponse.data.count);
      setCardDetails((prevState: any) => [
        ...prevState,
        ...VenueResponse.data.data,
      ]);
    } catch (err) {
      setLoading(false);
    }
  };

  const getSponsorList = async () => {
    Axios.get(`web/sponsor-list`, {
      params: {
        venueId: venueId,
      },
    })
      .then((response) => {
        setSponserList(response?.data?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getVenuesDetails = async () => {
    try {
      setLoading(true);
      const VenueResponse = await Axios.post(`venue`, {
        slug: params.id,
      });
      setVenue(VenueResponse?.data?.data);
      setLoading(false);
      setVenueId(VenueResponse?.data?.data?._id);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleClick = () => {
    setIsActive((current) => !current);
  };
  useEffect(() => {
    getVenuesDetails();
  }, []);

  useEffect(() => {
    if (venueId.length > 0) getSponsorList();
  }, [venueId]);

  useEffect(() => {
    if (venueId.length > 0) getCurrentPageData();
  }, [venueId, currentPage, pageSize]);

  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }

  const modalHideHandler = () => {
    setShowImagePreview(false);
    setSettingIndex(0);
  };

  const previewHandleClick = (index: any) => {
    setSettingIndex(index);
    setShowImagePreview(true);
  };

  const galleryModalHideHandler = () => {
    setShowGalleryPreview(false);
    setShowGalleryPreview(0);
  };

  const previewGalleryHandleClick = (index: any) => {
    setGalleryIndex(index);
    setShowGalleryPreview(true);
  };
  return (
    <>
      <div className="MainCommon">
        {venue ? (
          <div className="VenueDetailSection">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="VenueDetailHead border-BottomClass">
                    <div className="VenueDetailHeading">
                      <h1>{venue?.title}</h1>
                    </div>
                    <div className="BackToVenueSec">
                      <Link to="/Venue">
                        <button
                          type="button"
                          className="BackToVenueBtn btn-primary"
                        >
                          BACK TO VENUES LIST
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="VenueCompanyDetails-Section boxStyle">
                    <div className="VenueCompanyImg">
                      <button
                        type="button"
                        className="btn ModalButton"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <img src={venue?.bannerImage} alt="" />
                      </button>
                    </div>
                    <div className="VenueDirection">
                      <div className="VenueDirectionHeading">
                        <h3>DIRECTIONS</h3>

                        {venue?.directions && <p>{parse(venue?.directions)}</p>}
                      </div>
                      <div className="VenueParkingHead">
                        <div className="ParkingHeading">
                          <h3>PARKING</h3>
                          {venue?.description && (
                            <p>{parse(venue?.description)}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="VenueCompanyDetails-Section VenueContent boxStyle">
                    <h3>TERMS & RULES</h3>
                    {venue?.termsAndRules && (
                      <p>{parse(venue?.termsAndRules)}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="BoxOfficeSection">
                    <div className="VenueBoxSec boxStyle">
                      <h3> BOX OFFICE</h3>
                      {venue?.boxOfficePhone &&
                        parse(`<p>${venue?.boxOfficePhone}</p>`)}
                      <p>
                        {venue?.boxOfficeWorkingHours &&
                          parse(venue?.boxOfficeWorkingHours)}
                      </p>
                      <p>{venue?.info && parse(venue?.info)}</p>
                    </div>
                    <div className="SeatingChartSec boxStyle">
                      <h3>SEATING CHART</h3>
                      <div className="SeatingChartImg">
                        <div className="Images">
                          {venue?.sittingChartImage?.map(
                            (item: any, index: any) => {
                              return (
                                <img
                                  src={item}
                                  alt=""
                                  key={index}
                                  onClick={() => previewHandleClick(index)}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="PaymentAccpSec boxStyle">
                      <div className="PaymentHead">
                        <h3>TYPES OF PAYMENTS ACCEPTED</h3>
                        {venue?.typeOfPayments?.map((e: any, index: any) => {
                          return <p key={index}>{e},</p>;
                        })}
                      </div>
                    </div>
                    {venue?.galleryImages &&
                      venue?.galleryImages?.length > 0 && (
                        <div className="galleryMedia boxStyle">
                          <h3>GALLERY</h3>
                          <div className="galleryContent">
                            {venue?.galleryImages.map(
                              (item: any, index: number) => {
                                return (
                                  <img
                                    src={item}
                                    alt=""
                                    onClick={() =>
                                      previewGalleryHandleClick(index)
                                    }
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              {/* MODAL */}
              {/* <!-- Modal --> */}
              <div
                className="modal fade modal-xl"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <img src={venue?.bannerImage} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal end */}
              <div className="venueCardList">
                <div className="StoreSec">
                  {sponsorList?.length > 0 && (
                    <Carousel
                      className="venueCardSlider"
                      interval={3000}
                      indicators={sponsorList.length > 1 ? true : false}
                      controls={sponsorList.length > 1 ? true : false}
                    >
                      {sponsorList.map((e: any, index: any) => {
                        return (
                          <Carousel.Item key={index}>
                            <img
                              className="d-block w-100"
                              src={e.image}
                              alt="First slide"
                            />
                            <Carousel.Caption>
                              <div className="StoreCaption">
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={e.link}
                                >
                                  <button className="StoreBanBTN" type="button">
                                    {e.name}
                                  </button>
                                </a>
                              </div>
                            </Carousel.Caption>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  )}
                </div>
              </div>
            </div>
            <div className="VenueCardUpcomingSection">
              <div className="container">
                <div className="row border-BottomClass">
                  <div className="VenueCardUpcomingShows">
                    <div className="featuredHeading">
                      <h1>UPCOMING SHOWS IN {venue?.title} </h1>
                    </div>

                    <div className="SelectSec">
                      <div className="GridIcon">
                        <Icons handleClick={handleClick} isActive={isActive} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {cardDetails.length > 0 ? (
                    isActive ? (
                      cardDetails.map((item: any, index: any) => {
                        return (
                          <Card
                            key={index}
                            events={item}
                            total={total}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        );
                      })
                    ) : (
                      <>
                        {cardDetails.length > 0
                          ? cardDetails.map((item: any, index: any) => {
                              return (
                                <>
                                  <SecondCard
                                    item={item}
                                    index={index}
                                    key={index}
                                    total={total}
                                  />
                                </>
                              );
                            })
                          : ""}
                      </>
                    )
                  ) : (
                    <h2 className="NoCardDetails">
                      Oops! There are currently no shows available in your city.
                      check back soon
                    </h2>
                  )}
                </div>
              </div>
            </div>
            <div className="LoadMoreBtn">
              {cardDetails.length !== total && (
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  type="button"
                  className="btn LoadMore"
                >
                  Load more events
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="NoData">
              {venue ? <img src={NoDataImage} alt=" " /> : ""}
            </div>
          </>
        )}
      </div>

      {showImagePreview ? (
        <GalleryPreviewModal
          show={showImagePreview}
          images={venue?.sittingChartImage}
          modalHideHandler={modalHideHandler}
          imageIndex={settingIndex}
        ></GalleryPreviewModal>
      ) : (
        <></>
      )}

      {showGalleryPreview ? (
        <GalleryPreviewModal
          show={showGalleryPreview}
          images={venue?.galleryImages}
          modalHideHandler={galleryModalHideHandler}
          imageIndex={galleryIndex}
        ></GalleryPreviewModal>
      ) : (
        <></>
      )}
    </>
  );
}
