import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../TextError';

function CheckboxGroup(props:any) {
  const { name, containerStyle, fieldStyle, options, ...rest } = props;
  return (
    <div className={containerStyle}>
      <Field name={name} className={fieldStyle}>
        {({ field }:any) => {
          return options.map((option:any) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type="checkbox"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default CheckboxGroup;
