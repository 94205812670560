import React, { useState } from "react";
import Axios from "../../Service/Instance";
import "./contact.scss";
import { ToastSuccess } from "../../Assets/Toaster/ToastMsg";

export default function Contact() {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const SubmitUserDetails = async (e: any) => {
    e.preventDefault();
    try {
      const response: any = await Axios.post("/contact-concerts", {
        name: userDetails.name,
        email: userDetails.email,
        subject: userDetails.subject,
        message: userDetails.message,
      });
      if (response) {
        ToastSuccess(response?.data?.message);
        setUserDetails({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div className="MainCommon">
        <div className="ContactSection">
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div className="ContactHeadSec border-BottomClass">
                  <div className="ContactHeading  ">
                    <h1>CONTACT WATERFRONT CONCERTS</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="ContactFormSec boxStyle">
                  <form onSubmit={(e) => SubmitUserDetails(e)}>
                    <div className="NameDiv">
                      <div className="row">
                        <div className="col-sm-6">
                          <label
                            htmlFor="formGroupExampleInput"
                            className="form-label"
                          >
                            Your Name
                          </label>

                          <input
                            required
                            type="text"
                            className="form-control"
                            aria-label="First name"
                            value={userDetails.name}
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                name: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-sm-6">
                          <label
                            htmlFor="formGroupExampleInput"
                            className="form-label"
                          >
                            Your Email
                          </label>

                          <input
                            type="email"
                            required
                            className="form-control"
                            aria-label="email"
                            value={userDetails.email}
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="formGroupExampleInput"
                        className="form-label"
                      >
                        Subject
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="formGroupExampleInput"
                        value={userDetails.subject}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            subject: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="formGroupExampleInput"
                        className="form-label"
                      >
                        Message
                      </label>
                      <div className="form-floating">
                        <textarea
                          required
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                          value={userDetails.message}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              message: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="ContactSubmitBtn">
                      <button type="submit">SUBMIT</button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-4">
                <div className="SocialMediaSec boxStyle">
                  <h3>SOCIAL MEDIA</h3>
                  <div>
                    <a
                      href="https://www.facebook.com/wfconcertsME"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                      Facebook
                    </a>
                  </div>

                  <div>
                    <a href="https://twitter.com/" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                      Twitter
                    </a>
                  </div>

                  <div>
                    <a
                      href="https://www.youtube.com/user/wfconcerts"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                      Youtube
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.instagram.com/wfconcerts/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                      Instagram
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.snapchat.com/add/wfconcerts"
                      target="_blank"
                    >
                      <i className="fa-brands fa-snapchat"></i>
                      Snapchat
                    </a>
                  </div>
                </div>
                <div className="SocialMediaSec boxStyle">
                  <h3>PHONE NUMBERS</h3>
                  <div>
                    <p className="LocationName"> Ticketmaster Box Office:</p>
                    <a href="tel:1-800-358-3000" className="LocationNumber">
                      Call 1-800-358-3000
                    </a>
                  </div>
                  <div>
                    <p className="LocationName"> Bangor Office Phone:</p>
                    <a href="tel:207-358-9327" className="LocationNumber">
                      Call 207-358-9327
                    </a>
                  </div>
                  <div>
                    <p className="LocationName"> Bangor Office Fax:</p>
                    <a href="tel:207-827-3508" className="LocationNumber">
                      Send 207-827-3508
                    </a>
                  </div>
                </div>
                <div className="EmailSec boxStyle">
                  <h3>EMAILS</h3>
                  <div>
                    <div>
                      <p className="LocationName"> General Information:</p>
                      <a
                        href="mailto:info@waterfrontconcerts.com"
                        className="LocationEmail"
                      >
                        info@waterfrontconcerts.com
                      </a>
                    </div>
                    <div>
                      <p className="LocationName"> Sponsorship Inquiries:</p>
                      <a
                        href="mailto:sponsorship@waterfrontconcerts.com"
                        className="LocationEmail"
                      >
                        sponsorship@waterfrontconcerts.com
                      </a>
                    </div>
                    <div>
                      <p className="LocationName">
                        Charitable Donation Request:
                      </p>
                      <a
                        href="mailto:donations@waterfrontconcerts.com"
                        className="LocationEmail"
                      >
                        donations@waterfrontconcerts.com
                      </a>
                    </div>
                    <div>
                      <p className="LocationName"> Volunteer Inquiries:</p>
                      <a
                        href="mailto:volunteer@waterfrontconcerts.com"
                        className="LocationEmail"
                      >
                        volunteer@waterfrontconcerts.com
                      </a>
                    </div>
                    <div>
                      <p className="LocationName"> Employment Opportunities:</p>
                      <a
                        href="mailto:employment@waterfrontconcerts.com"
                        className="LocationEmail"
                      >
                        employment@waterfrontconcerts.com
                      </a>
                    </div>
                    <div>
                      <p className="LocationName">
                        Security Staff Opportunities:
                      </p>
                      <a
                        href="mailto:ben@productionservicesofmaine.com"
                        className="LocationEmail"
                      >
                        ben@productionservicesofmaine.com
                      </a>
                    </div>
                    <div>
                      <p className="LocationName">Local Booking Requests:</p>
                      <a
                        href="mailto:booking@waterfrontconcerts.com"
                        className="LocationEmail"
                      >
                        booking@waterfrontconcerts.com
                      </a>
                    </div>
                    <div>
                      <p className="LocationName">Ticketing Questions:</p>
                      <a
                        href="mailto:tickets@waterfrontconcerts.com"
                        className="LocationEmail"
                      >
                        tickets@waterfrontconcerts.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
