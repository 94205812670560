import './faqCommon.scss';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

export default function General(props:any) {
  const { id, question, answer }=props
  return (
    <div>
      <div className="accordion" id="accordionExample ">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey={id}>
            <Accordion.Header>{question}</Accordion.Header>
            <Accordion.Body>{answer}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
