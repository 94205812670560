import React, { useEffect, useState } from "react";
import Axios from "../../Service/Instance";
import { prePareCityList, prePareVanueList } from "./helper";
import FeatureCard from "./FeatureCard";
import Card from "../CardSec/Card";
import SecondCard from "../CardSec/SecondCard";
import "../Featured/featured.scss";
import Select from "react-select";
import Icons from "./Icons";
import IframeModel from "../../model/IframeModel";
import "react-multi-carousel/lib/styles.css";
import StoreBanner from "../StoreBanner/StoreBanner";
import LoadingImage from "../../Assets/Images/loading_Gif.gif";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Featured = (props: any) => {
  const [isActive, setIsActive] = useState(false);
  const [events, setEvents] = useState<any>([]);
  const [eventsDataBasedOnLocation, setEventsDataBasedOnLocation] =
    useState<any>([]);
  const [total, setTotal] = useState();
  const [modelShow, setModelShow] = useState(false);
  const iframeLink = "https://engage.ticketmaster.net/WaterfrontConcerts/SignUp";

  const [defaultSelectedValue, setDefaultSelectedValue] = useState<any>({
    value: 0,
    label: "ALL",
  });
  const [cityList, setCityList] = useState<any>([]);
  const [vanueList, setVenueList] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState<any>("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVenue, setSelectedVenue] = useState<any>(null);
  const [pageSize, setPageSize] = useState(12);
  const [addBanner, setAddBanner] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [onSaleEventCurrentPage, setOnSaleEventCurrentPage] = useState(1);
  const [onSaleEventTotal, setOnSaleEventTotal] = useState(0);

  const getEventDataSortByLocation = async () => {
    const response = await Axios.get(`/events`, {
      params: {
        currentPage: onSaleEventCurrentPage,
        pageSize: pageSize,
        eventType: "ONSALE",
        sortBy: "location",
      },
    });
    setOnSaleEventTotal(response.data.count);
    setEventsDataBasedOnLocation((prevState: any) => [
      ...prevState,
      ...response.data.data,
    ]);
  };

  const fetchEvents = () => {
    if (events.length == 0) {
      setLoading(true);
    }

    Axios.get(`events`, {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
        eventType: "UPCOMING",
        sortBy: "UPCOMING",
        cityId: selectedCity?.value,
        venueId: selectedVenue?.value,
      },
    })
      .then((response) => {
        setLoading(false);
        setTotal(response.data.count);
        setEvents((prevState: any) => [...prevState, ...response.data.data]);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getCityList = async () => {
    const response = await Axios.get("cities");
    let cityList = prePareCityList(response.data.data);
    cityList = [defaultSelectedValue, ...cityList];
    setCityList(cityList);
  };
  const getVanueList = async () => {
    const response = await Axios.get("venues");
    let List = prePareVanueList(response.data.data);
    List = [defaultSelectedValue, ...List];
    setVenueList(List);
  };
  const handleCityChange = (selectedCity: any) => {
    setEvents([]);
    setCurrentPage(1);
    setSelectedCity(selectedCity);
  };
  const handleVanueChange = (e: any) => {
    setEvents([]);
    setCurrentPage(1);
    setSelectedVenue(e);
  };
  const handleClick = () => {
    setIsActive((current) => !current);
  };
  const fetchBannerDetails = async () => {
    try {
      const response = await Axios.get(`adBanner`);
      if (response.data.status === 1) {
        setAddBanner(response.data.data || []);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getVanueList();
    getCityList();
    fetchBannerDetails();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [currentPage, selectedCity, selectedVenue]);

  useEffect(() => {
    getEventDataSortByLocation();
  }, [onSaleEventCurrentPage]);

  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="ShowsSection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="featuredShowSec border-BottomClass">
                <div className="featuredContent">
                  <div className="featuredHeading">
                    <h1>
                      {props?.getTitles?.onSaleSectionTitle
                        ? props?.getTitles?.onSaleSectionTitle
                        : "ON SALE NOW"}
                    </h1>
                  </div>
                  <div className="GetUpdateSec">
                    <button
                      type="button"
                      className="UpdateSecBtn btn-primary"
                      onClick={() => {
                        window.open("https://submit.waterfrontconcerts.com/submit_form.html");
                      }}
                    >
                      GET UPDATE ON OUR EVENTS
                    </button>
                  </div>
                </div>
              </div>

              {eventsDataBasedOnLocation?.length > 0 ? (
                <>
                  <div className="FeaturedCardSec row">
                    {eventsDataBasedOnLocation.map(
                      (value: any, index: number) => {
                        return (
                          <>
                            <FeatureCard
                              data={value}
                              key={index}
                              getTitles={props.getTitles}
                            />
                          </>
                        );
                      }
                    )}
                  </div>
                  <div className="row">
                    {
                      <div className="LoadMoreBtn">
                        {eventsDataBasedOnLocation.length !==
                          onSaleEventTotal &&
                          onSaleEventTotal > pageSize && (
                            <button
                              onClick={() =>
                                setOnSaleEventCurrentPage(
                                  onSaleEventCurrentPage + 1
                                )
                              }
                              type="button"
                              className="btn LoadMore"
                            >
                              Load more
                            </button>
                          )}
                      </div>
                    }
                  </div>
                </>
              ) : (
                <div className="dataNotFound">
                  <p className="NoFoundPara">
                    Oops! There are currently no shows available in your city.
                    check back soon.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="StoreBannerSec">
            <StoreBanner />
          </div>
          <div className="row border-BottomClass upcomingShowSec">
            <div className="featuredContent">
              <div className="featuredHeading">
                <h1>
                  {props?.getTitles?.upcomingSectionTitle
                    ? props?.getTitles?.upcomingSectionTitle
                    : "UPCOMING SHOWS"}
                </h1>
              </div>
              <div className="SelectSec">
                <div className=" locationContent">
                  <h5 className="selectHeading">LOCATION :</h5>
                  <span className="LocationField">
                    <Select
                      options={cityList}
                      value={selectedCity}
                      onChange={handleCityChange}
                    />
                  </span>
                </div>
                <div className="Venue">
                  <div className=" locationContent">
                    <h5 className="selectHeading">VENUE :</h5>
                    <span className="LocationField">
                      <Select
                        options={vanueList}
                        value={selectedVenue}
                        onChange={(e) => handleVanueChange(e)}
                      />
                    </span>
                  </div>
                </div>
                <div className="GridIcon">
                  <Icons handleClick={handleClick} isActive={isActive} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {events.length > 0 ? (
              <>
                {events.map((item: any, index: any) => {
                  return (
                    <>
                      {isActive ? (
                        <Card
                          events={item}
                          total={total}
                          index={index}
                          key={index}
                        />
                      ) : (
                        <SecondCard
                          item={item}
                          index={index}
                          key={index}
                          total={total}
                        />
                      )}
                      {(index + 1) % 4 === 0 &&
                      Number.isInteger((index + 1) / 4 - 1) ? (
                        <div
                          className={
                            addBanner[(index + 1) / 4 - 1]?.image
                              ? "AddStoreSection"
                              : "noStoreBanner"
                          }
                        >
                          <a
                            href={addBanner[(index + 1) / 4 - 1]?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="AddStoreSec">
                              {addBanner[(index + 1) / 4 - 1]?.image && (
                                <>
                                  <img
                                    src={addBanner[(index + 1) / 4 - 1]?.image}
                                    alt=""
                                  />
                                </>
                              )}
                            </div>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                <div className="row">
                  {
                    <div className="LoadMoreBtn">
                      {events.length !== total && currentPage <= pageSize && (
                        <button
                          onClick={() => setCurrentPage(currentPage + 1)}
                          type="button"
                          className="btn LoadMore"
                        >
                          Load more events
                        </button>
                      )}
                    </div>
                  }
                </div>
              </>
            ) : (
              <div className="dataNotFound">
                <p className="NoFoundPara">
                  Oops! There are currently no shows available in your city.
                  check back soon.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {modelShow ? (
        <IframeModel
          link={iframeLink}
          width="100%"
          height="600"
          modelShow={modelShow}
          setModelShow={setModelShow}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Featured;
