import React, { useState } from "react";
import "./StoreBanner.scss";
import { useEffect } from "react";
import Axios from "../../Service/Instance";
import Carousel from "react-bootstrap/Carousel";
export default function StoreBanner() {
  const [storeBanner, setStoreBanner] = useState<any>();

  const StoreBannerApi = async () => {
    try {
      const response = await Axios.get(`storeBanners`);
      setStoreBanner(response.data.data);
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    StoreBannerApi();
  }, []);

  return (
    <>
      <div className="StoreSec">
        {storeBanner?.length > 0 && (
          <Carousel
            interval={3000}
            indicators={storeBanner.length > 1 ? true : false}
            controls={storeBanner.length > 1 ? true : false}
          >
            {storeBanner.map((e: any, index: any) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={e.image}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <div className="StoreCaption">
                      <a target="_blank" rel="noreferrer" href={e.link}>
                        <button className="StoreBanBTN" type="button">
                          {e.title}
                        </button>
                      </a>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
      </div>
    </>
  );
}
