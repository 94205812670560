import React, { useEffect, useState } from "react";
import Axios from "../../Service/Instance";
import LoadingImage from "../../Assets/Images/loading_Gif.gif";
import "./job.scss";
import { useNavigate } from "react-router-dom";
import { ToastFailure } from "../../Assets/Toaster/ToastMsg";

export default function Job() {
  const [jobListing, setJobListing] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [checkList, setcheckList] = useState<any>([]);
  const [checked, setChecked] = useState<any>([]);
  const [searchTitle, setSearchTitle] = useState();
  const [checkedItem, setCheckedItem] = useState<any>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      getJobsData();
    }, 400);
    return () => {
      clearTimeout(timer);
    };
  }, [searchTitle, checkedItem]);

  // Get Category list
  const getCategoryList = async () => {
    const response = await Axios.get(`web/jobsByCategory`, {
      headers: {
        Authorization: sessionStorage.getItem("AUTHTOKEN") || "",
      },
    });
    setcheckList(response?.data?.data);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  // get Teams And Rules Data
  const getJobsData = async () => {
    await Axios.get(`/web/jobs`, {
      params: {
        search: searchTitle,
        jobCategory: checkedItem,
      },
      headers: {
        Authorization: sessionStorage.getItem("AUTHTOKEN") || "",
      },
    })
      .then((response) => {
        if (response?.data?.data) {
          setJobListing(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
        ToastFailure(error.response.message);
      });
  };

  // Add/Remove checked item from list
  const handleCheck = (event: any) => {
    const { id, checked } = event.target;
    if (checked) {
      setCheckedItem([...checkedItem, event.target.value]);
    } else {
      setCheckedItem(
        checkedItem.filter((checkedId: any) => checkedId !== event.target.value)
      );
    }
  };

  // Return classes based on whether item is checked
  const isChecked = (item: any) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="JobSection">
        <div className="container-fluid">
          <div className="JobBG">
            <div className="JobHead">
              <div className="JobHeadingDiv">
                <h1 className="JobsHeading">
                  BE ONE <br /> OF US
                </h1>
              </div>
              <div className="JobParaDiv">
                <h3>
                  Looking to work for Waterfront Concerts /
                  <p> Production Services of Maine this summer?</p>
                  <p className="GreatHeading">GREAT!</p>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="JobDetailSection">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="HiringSec boxStyle">
                  <div className="CategorySearch">
                    <div className="SearchSec">
                      {/* in progress */}
                      <input
                        type="search"
                        placeholder="Search for a job"
                        onChange={(e: any) => setSearchTitle(e.target.value)}
                      ></input>
                      <i className="fa fa-search searchIcon"></i>
                    </div>
                    <div className=" CategorySelect">
                      <h3>Select Categories</h3>
                      <div className="categoryRadioBtn">
                        {checkList.map((item: any, index: any) => {
                          return (
                            <div className="SelectCategoryDiv" key={index}>
                              <input
                                value={item.categoryName}
                                id={item._id}
                                name={item.categoryName}
                                type="checkbox"
                                onChange={handleCheck}
                              />
                              <span className={isChecked(item.categoryName)}>
                                {item.categoryName}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="PositionSec">
                  {/* <div className="PositionContent boxStyle">
                    <h3>
                      Available positions at Maine Savings Amphitheater (Bangor)
                    </h3>
                  </div> */}
                  {jobListing && jobListing.length > 0 ? (
                    jobListing.map((list: any, index: any) => {
                      const { title, description, isActive, _id, jobcategory } =
                        list;
                      return (
                        <div className="SecurityContent boxStyle" key={index}>
                          <div className="CategoryHeading">
                            <p>{jobcategory ? jobcategory : ""}</p>
                          </div>
                          <div className="SecurityHeading">
                            <h3>{title}</h3>
                          </div>
                          <div className="CategoryPara">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}
                            />
                          </div>
                          <div className="ApplyBtn">
                            {isActive && (
                              <a>
                                <span
                                  onClick={() => {
                                    navigate(`/JobChild`, {
                                      state: { _id },
                                    });
                                  }}
                                >
                                  <button type="button" className="btn-primary">
                                    APPLY NOW
                                  </button>
                                </span>
                              </a>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="dataNotFound bg-colour">
                      <p className="NoFoundPara">NO JOB AVAILABLE.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
