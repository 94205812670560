import React, { useEffect, useState } from "react";
import io from "socket.io-client";

import notiIcon from "../Assets/Images/noti_icon.png";

const socket = io(`${process.env.REACT_APP_SOCKET_URL}`);

const useSocketService = () => {
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Socket Connected");
    });

    socket.on("notifyWebEvent", (notification: any) => {
      console.log("Received notification:", notification);
      showNotification(notification);
    });

    return () => {
      socket.on("disconnect", () => {
        console.log("Disconnected from Socket.IO server");
      });
    };
  }, []);

  const showNotification = (data: any) => {
    const options = {
      body: data.message,
      tag: data._id,
      icon: notiIcon,
    };

    if (Notification.permission === "granted") {
      new Notification("Waterfront Concerts", options);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification("Waterfront Concerts", options);
        }
      });
    }
  };
};

export default useSocketService;
