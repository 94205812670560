import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "../../../Service/Instance";
import "../event.scss";
import redTop from "../../../Assets/Images/red_top.png";
import redBottom from "../../../Assets/Images/red_bottom.png";
import blueTop from "../../../Assets/Images/blueTop.png";
import blueBottom from "../../../Assets/Images/blueBottom.png";
import moment from "moment";
import LoadingImage from "../../../Assets/Images/loading_Gif.gif";
import EventChildModel from "../../../model/event-child-model/EventChildModel";
import parse from "html-react-parser";
import ImagePreviewModal from "../../../model/image-preview-modal/imagePreviewModal";
import radio from "../../../Assets/Images/ic-radio.png";
import spotify from "../../../Assets/Images/ic-spotif.png";
import attach from "../../../Assets/Images/ic-attach.png";
import GalleryPreviewModal from "../../../model/gallery-preview-modal/galleryPreviewModal";

export default function EventChlid() {
  const [loading, setLoading] = useState<any>(false);
  const [eventChild, setEventChild] = useState<any>();
  const [show, setShow] = useState<any>({
    status: false,
    content: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  const [showImagePreview, setShowImagePreview] = useState<any>(false);
  const [imageUrlForPreview, setImageUrlForPreview] = useState<any>("");

  const [showGalleryPreview, setShowGalleryPreview] = useState<any>(false);
  const [galleryIndex, setGalleryIndex] = useState<any>(0);
  const [preSaleActiveFlag, setpreSaleActiveFlag] = useState<any>(false);

  const getEventDataSortByLocation = async () => {
    try {
      setLoading(true);
      const EventResponse = await Axios.post("/event", {
        slug: params.id,
      });

      setEventChild(EventResponse.data.data);

      const preSaleAllActive = EventResponse?.data?.data?.preSales.every(
        (item: any) => item.isActive === false
      );
      setpreSaleActiveFlag(!preSaleAllActive);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const onNavigate = (_id: any, slug: any) => {
    navigate(`/Venue/VenueCardDetails/${slug}`, { state: { id: _id } });
  };

  useEffect(() => {
    getEventDataSortByLocation();
  }, []);
  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }

  const showModel = (showStatus: any, discription: any, title: any) => {
    setShow({
      title: title,
      status: showStatus,
      content: discription,
    });
  };

  const imageModalHideHandler = () => {
    setShowImagePreview(false);
    setImageUrlForPreview("");
  };

  const previewHandleClick = (ImgURL: any) => {
    setImageUrlForPreview(ImgURL);
    setShowImagePreview(true);
  };

  const galleryModalHideHandler = () => {
    setShowGalleryPreview(false);
    setShowGalleryPreview(0);
  };

  const previewGalleryHandleClick = (index: any) => {
    setGalleryIndex(index);
    setShowGalleryPreview(true);
  };

  return (
    <>
      <div className="MainCommon">
        <div className="VenueDetailSection EventChild">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="VenueDetailHead border-BottomClass">
                  <div className="VenueDetailHeading">
                    <h1>{eventChild?.title}</h1>
                  </div>
                  <div className="BackToVenueSec">
                    <Link to="/">
                      <button
                        type="button"
                        className="BackToVenueBtn btn-primary"
                      >
                        BACK TO EVENT LIST
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="VenueCompanyDetails-Section boxStyle">
                  <div className="VenueCompanyImg">
                    <div className="bannerTime">
                      {eventChild &&
                        moment(eventChild?.startDateTime).format("MMM")}
                      <p>
                        {eventChild &&
                          moment(eventChild?.startDateTime).format("DD")}
                      </p>
                    </div>
                    <button
                      type="button"
                      className="btn ModalButton"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <img src={eventChild?.bannerImage} alt="" />
                    </button>
                  </div>

                  <div className="EventDirectionHeading">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: eventChild?.description,
                      }}
                    />
                  </div>
                </div>
                {eventChild?.termsAndRules.trim() && (
                  <div className="VenueCompanyDetails-Section EventChild-Details-Sec boxStyle">
                    <h3>Terms</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: eventChild?.termsAndRules,
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-4">
                <div className="BoxOfficeSection">
                  <div className="VenueBoxSec boxStyle EventChildBox">
                    {eventChild?.tickets?.length > 0 &&
                      eventChild?.isOnsale && (
                        <p className="BuyNowHeading">Buy Now</p>
                      )}
                    {eventChild?.tickets?.length > 0 &&
                      !eventChild?.isOnsale &&
                      eventChild?.onSaleDateTime && (
                        <p className="BuyNowHeading">
                          {`ON SALE ${moment(eventChild?.onSaleDateTime).format(
                            "MM/DD"
                          )} at ${moment(eventChild?.onSaleDateTime).format(
                            "hh:mm a"
                          )}`}
                        </p>
                      )}
                    <div>
                      <span className="ShowHeading">
                        <strong> Show Time: </strong>
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            eventChild &&
                            moment(eventChild?.startDateTime).format("LT"),
                        }}
                      />
                      <span> (ET)</span>
                    </div>
                    <div>
                      <span className="ShowHeading">
                        <strong> Door Time: </strong>
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            eventChild &&
                            moment(eventChild?.doorTime).format("LT"),
                        }}
                      />
                      <span> (ET)</span>
                    </div>
                    <p
                      className="ShowLink"
                      onClick={() =>
                        onNavigate(eventChild.venue._id, eventChild.venue.slug)
                      }
                    >
                      {eventChild?.venue.title}
                    </p>
                  </div>

                  {eventChild?.preSales &&
                    eventChild?.preSales?.length > 0 &&
                    preSaleActiveFlag && (
                      <div className="mediaSec boxStyle">
                        <h3>PRESALES</h3>
                        {eventChild?.preSales?.map((item: any, index: any) => {
                          return (
                            <>
                              {item.isActive && (
                                <>
                                  <div className="mediaContent">
                                    {item.sponsor ? (
                                      <p>
                                        <b>{`Sponsor: ${item.sponsor}`}</b>
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                    <p style={{ marginBottom: "0px" }}>
                                      {`Start:
                                    ${moment(item?.startPresaleDateTime).format(
                                      "ddd MM/DD/YY"
                                    )} 
                                    ${moment(item?.startPresaleDateTime).format(
                                      "hh:mm a"
                                    )}`} (ET)
                                    </p>
                                    <p style={{ marginBottom: "0px" }}>
                                      {`End:
                                    ${moment(item?.endPresaleDateTime).format(
                                      "ddd MM/DD/YY"
                                    )} 
                                    ${moment(item?.endPresaleDateTime).format(
                                      "hh:mm a"
                                    )}`} (ET)
                                    </p>
                                  </div>
                                  {index !==
                                    eventChild?.preSales?.length - 1 && <hr />}
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}

                  {eventChild?.tickets?.length > 0 ? (
                    eventChild?.tickets.map((item: any) => {
                      return (
                        <div className="ReserveSeating" key={item._id}>
                          <a href={item.link} target="_blank">
                            <img className="redTopImg" src={redTop} alt="" />
                            <div className="ReservedSec preSaleContent boxStyle">
                              <h3>{item.name}</h3>
                            </div>
                            <div>
                              <div className="BgRedBottom">
                                <div className="EventPriceDiv">
                                  <h5 className="PriceClass">
                                    {item?.isReducedPrice ? (
                                      item.reducePrice
                                    ) : (
                                      <>
                                        ${item.price}
                                        {item?.price1 == undefined
                                          ? ""
                                          : ` - $${item?.price1}`}
                                      </>
                                    )}
                                  </h5>
                                  <a className="PriceClass">BUY</a>
                                </div>
                                <img
                                  className="redBottomImg"
                                  src={redBottom}
                                  alt=""
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  {eventChild?.packages?.length > 0 ? (
                    eventChild?.packages.map((item: any) => {
                      return (
                        <div className="ReserveSeating" key={item._id}>
                          <a>
                            <img
                              className="redTopImg blueTop"
                              src={blueTop}
                              alt=""
                            />
                            <div className="ReservedSec boxStyle">
                              <h3> {item.title} </h3>
                              <h4
                                className="Read_more"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  showModel(
                                    true,
                                    parse(item.description),
                                    item.title
                                  )
                                }
                              >
                                Read More....
                              </h4>
                              <p className="ModalElipsis">
                                {parse(item.description)}
                              </p>
                            </div>
                            <div>
                              <div className="BgRedBottom">
                                {item.price && (
                                  <a href={item.ticketLink} target="_blank">
                                    <div className="EventPriceDiv">
                                      <h5 className="PriceClass">
                                        ${item.price}
                                      </h5>
                                      <p className="PriceClass">BUY</p>
                                    </div>
                                  </a>
                                )}

                                <img
                                  className="redBottomImg"
                                  src={blueBottom}
                                  alt=""
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  <div className="SeatingChartSec boxStyle">
                    <h3>SEATING CHART</h3>
                    <div className="EventChildSettingImg ">
                      <img
                        className="EventSettingImg"
                        src={eventChild?.venueSittingChartImage}
                        alt=""
                        onClick={() =>
                          previewHandleClick(eventChild?.venueSittingChartImage)
                        }
                      />
                    </div>
                  </div>
                  {eventChild?.media && eventChild?.media?.length > 0 && (
                    <div className="mediaSec boxStyle">
                      <h3>MEDIA</h3>
                      {eventChild?.media.map((item: any) => {
                        return (
                          <div className="mediaContent">
                            <img
                              src={
                                item.type === "Attach"
                                  ? attach
                                  : item.type === "Spotify"
                                  ? spotify
                                  : radio
                              }
                              alt=""
                            />
                            <a href={item.link} target="_blank">
                              {item.title}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {eventChild?.galleryImages &&
                    eventChild?.galleryImages?.length > 0 && (
                      <div className="galleryMedia boxStyle">
                        <h3>GALLERY</h3>
                        <div className="galleryContent">
                          {eventChild?.galleryImages.map(
                            (item: any, index: number) => {
                              return (
                                <img
                                  src={item}
                                  alt=""
                                  onClick={() =>
                                    previewGalleryHandleClick(index)
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade modal-xl"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <img src={eventChild?.bannerImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show.status ? (
        <EventChildModel
          show={show}
          setShow={setShow}
          title={show.title}
          discription={show.content}
        />
      ) : (
        <></>
      )}

      {showImagePreview ? (
        <ImagePreviewModal
          show={showImagePreview}
          imageUrl={imageUrlForPreview}
          modalHideHandler={imageModalHideHandler}
        ></ImagePreviewModal>
      ) : (
        <></>
      )}

      {showGalleryPreview ? (
        <GalleryPreviewModal
          show={showGalleryPreview}
          images={eventChild?.galleryImages}
          modalHideHandler={galleryModalHideHandler}
          imageIndex={galleryIndex}
        ></GalleryPreviewModal>
      ) : (
        <></>
      )}
    </>
  );
}
