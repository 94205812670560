import React from "react";
import { Formik, Form } from "formik";
import FormikControl from "../../FormikControl/FormikControl";
import * as Yup from "yup";
import Axios from "../../../Service/Instance";
import { ToastFailure, ToastSuccess } from "../../../Assets/Toaster/ToastMsg";

function LostAndClaimForm() {
  const initialValues = {
    lostItem: "",
    description: "",
    name: "",
    phoneNumber: "",
    email: "",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    lostItem: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
  });

  const handleLostAndClaimFormData = async (values: any, submitProps: any) => {
    const getLostAndClaimDetail = await Axios.post(`lost-item-claim`, values);
    if (getLostAndClaimDetail.status == 200) {
      submitProps.resetForm();
      submitProps.setSubmitting(false);
      ToastSuccess(getLostAndClaimDetail.data.message);
    } else {
      submitProps.setSubmitting(false);
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleLostAndClaimFormData}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikControl
              control="inputWithLabel"
              label={
                <>
                  <span>Lost item</span>
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              type="text"
              name="lostItem"
              containerStyle="mb-3"
              labelStyle="form-label"
              fieldStyle="form-control"
            />
            <FormikControl
              control="textareaWithLabel"
              label="Description"
              type="text"
              name="description"
              containerStyle="mb-3"
              labelStyle="form-label"
              fieldStyle="form-control"
            />
            <FormikControl
              control="inputWithLabel"
              label={
                <>
                  <span>Name</span>
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              name="name"
              type="text"
              containerStyle="mb-3"
              labelStyle="form-label"
              fieldStyle="form-control"
            />

            <FormikControl
              control="inputWithLabel"
              label={
                <>
                  <span>PhoneNumber</span>
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              name="phoneNumber"
              type="text"
              containerStyle="mb-3"
              labelStyle="form-label"
              fieldStyle="form-control"
            />

            <FormikControl
              control="inputWithLabel"
              label={
                <>
                  <span>Email</span>
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              name="email"
              type="email"
              containerStyle="mb-3"
              labelStyle="form-label"
              fieldStyle="form-control"
            />
            <div className="mb-3">
              <button
                type="submit"
                disabled={isSubmitting}
                className="SubmitBtn"
              >
                SUBMIT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default LostAndClaimForm;
