import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../TextError';
import Select from 'react-select';
function SelectBoxWithLabel(props:any) {
  const {
    label,
    options,
    containerStyle,
    labelStyle,
    fieldStyle,
    errorMsgStyle,
    name,
    ...rest
  } = props;
  return (
    <div className={containerStyle}>
      <label htmlFor={name} className={labelStyle}>
        {label}
      </label>
      <Field name={name} className={fieldStyle}>
        {({ form, field }:any) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Select
              id={name}
              {...rest}
              options={options}
              value={value}
              onChange={(val) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name}
               component={`${<TextError errorMsgStyle={errorMsgStyle} />}`}

      />
    </div>
  );
}

export default SelectBoxWithLabel;
