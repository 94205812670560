import React from "react";
import bearIcon from "../../Assets/Images/bear-icon.png";
import roomImage1 from "../../Assets/Images/room-image1.png";
import roomImage2 from "../../Assets/Images/room-image2.png";
import roomImage3 from "../../Assets/Images/room-image3.png";

import "./Booking.scss";

export default function Booking() {
  return (
    <div className="bookingSection">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="bookingHeader">
              <img src={bearIcon} alt="" />
              <h1>STAY LIKE A STAR</h1>
              <h6>
                Waterfront Concerts is happy to partner with Hotel Ursa in an
                effort to provide concertgoers with a great lodging experience!
                Enjoy a stay at a distinctly Maine hotel that’s less than 10
                miles away from our venue.
              </h6>
            </div>
            <div className="bookNowHead">
              <h5>
                <a
                  href="https://be.synxis.com/?Hotel=43652&Chain=31484&config=PREOPEN&theme=initialTheme&promo=CONCERT"
                  target="_blank"
                >
                  {" "}
                  BOOK NOW
                </a>{" "}
                to unlock our exclusive concert rate!
              </h5>
            </div>
          </div>
        </div>
        <div className="roomsSection">
          <div className="row">
            <div className="col-md-5">
              <div>
                <h2>Accommodations</h2>
                <p>
                  Their well-appointed rooms provide a serene and comfortable
                  environment, perfect for unwinding after a lively night at
                  Maine Savings Amphitheater. Experience the ideal blend of
                  modern amenities and cozy charm that makes every stay
                  memorable.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <div>
                <img src={roomImage1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="roomsSection forMbFlex">
          <div className="row">
            <div className="col-md-7">
              <div>
                <img src={roomImage2} alt="" />
              </div>
            </div>
            <div className="col-md-5">
              <div>
                <h2>Close to the Action</h2>
                <p>
                  Enjoy a quick 20-minute scenic drive from the venue to the
                  hotel, making it easy to transition from the excitement of
                  live music to the tranquility of your room. Their prime
                  location on the UMaine campus ensures you’re close to all the
                  action while offering a peaceful retreat.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="roomsSection">
          <div className="row">
            <div className="col-md-5">
              <div>
                <h2>MajorMinor Café</h2>
                <p>
                  Kickstart the morning after a memorable night with locally
                  roasted coffee at their onsite café, MajorMinor. It’s the
                  perfect spot to relax, refuel, and relish the moments that
                  matter most.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <div>
                <img src={roomImage3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="bookingFooter">
              <h5>
                When you’re finished seeing the stars at Maine Savings
                Amphitheater, sleep under them at Hotel Ursa.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
