import React, { useEffect, useState } from "react";
import VenueCard from "./VenueCard/VenueCard";
import "./venue.scss";
import Axios from "../../Service/Instance";
import LoadingImage from "../../Assets/Images/loading_Gif.gif";

export default function Venue() {
  const [item, setItem] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [total, setTotal] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  function LoadMore() {
    setCurrentPage(currentPage + 1);
  }

  const fetchVenue = async () => {
    try {
      setLoading(true);
      const VenueResponse: any = await Axios.get(
        `venues?currentPage=${currentPage}&pageSize=${pageSize}`
      );
      setItem((prev: any) => [...prev, ...VenueResponse.data.data]);
      setTotal(VenueResponse.data.totalVenues);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchVenue();
  }, [currentPage]);

  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="MainCommon">
        <div className="VenueSection">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="VenueHeadSec">
                  <div className="VenueHeading border-BottomClass">
                    <h1>VENUES</h1>
                  </div>
                </div>
              </div>
            </div>
            {item.length > 0 ? (
              <>
                {" "}
                <div className="row">
                  {item.map((item: any, index: any) => {
                    return (
                      <VenueCard
                        key={index}
                        title={item.title}
                        image={item.featureImage}
                        address={item.address}
                        id={item._id}
                        slug={item.slug}
                      />
                    );
                  })}
                </div>
                <div className="row">
                  {
                    <div className="LoadMoreBtn">
                      {total !== item.length && (
                        <button
                          onClick={LoadMore}
                          type="button"
                          className="btn LoadMore"
                        >
                          Load more venues
                        </button>
                      )}
                    </div>
                  }
                </div>
              </>
            ) : (
              <>
                Oops! There are currently no venues available in your city.
                Check back soon.
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
