import React, { useEffect, useState } from "react";
import HeaderLogo from "../../Assets/Images/HeaderLogo.png";
import "../Navbar/navbar.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import Axios from "../../Service/Instance";
const Navbar = () => {
  const [subMenuData, setSubMenuData] = useState<any>([]);
  const [navId, setNavId] = useState<any>();
  const { pathname } = useLocation();

  const ticketSubMenu = async () => {
    const response: any = await Axios.get("/web/ticket-submenus");
    setSubMenuData(response.data.data);
  };

  const getIdForNavigation = async () => {
    try {
      const questionResponse: any = await Axios.get("web/questions");
      const vanueId =
        questionResponse.data.data.length > 0
          ? questionResponse.data.data[0]._id
          : "";
      setNavId(vanueId);
      sessionStorage.setItem("VENUE_ID", vanueId);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    ticketSubMenu();
    getIdForNavigation();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("VENUE_ID", navId);
  }, [navId]);

  return (
    <>
      <div className="NavbarSection">
        <nav className="navbar navbar-expand-lg ">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img className="HeaderLogoIcon" src={HeaderLogo} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <NavLink
                  className={
                    [`/`, `/EventChild/${pathname.split("/")[2]}`].includes(
                      pathname
                    )
                      ? `nav-bar-link active`
                      : `nav-bar-link`
                  }
                  to="/"
                >
                  <li className="nav-item">
                    <a className="nav-link active " aria-current="page">
                      EVENTS
                    </a>
                  </li>
                </NavLink>

                <NavLink className="nav-bar-link" to="/Venue">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      VENUES
                    </a>
                  </li>
                </NavLink>
                <NavLink className="nav-bar-link" to="/GuestService">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      GUEST SERVICES
                    </a>
                  </li>
                </NavLink>
                <NavLink
                  className={
                    ["/Job", "/JobChild"].includes(pathname)
                      ? `nav-bar-link active`
                      : ``
                  }
                  to="/Job"
                >
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      JOB
                    </a>
                  </li>
                </NavLink>
                {/* <NavLink> */}
                <li className="nav-item dropdown">
                  <NavLink
                    // className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={`${
                      [`/Season/${pathname.split("/")[2]}`].includes(pathname)
                        ? `nav-link dropdown-toggle`
                        : `nav-link dropdown-toggle`
                    }`}
                    // to="/Job"
                  >
                    TICKETS
                  </NavLink>
                  <ul className="dropdown-menu ticketDropdown">
                    {subMenuData
                      ? subMenuData
                          .filter((item: any) => item.title !== "Jobs")
                          .map((menu: any, index: any) => {
                            return (
                              <Link key={index} to={`/Season/${menu.slug}`}>
                                <li>
                                  <a className="dropdown-item ">{menu.title}</a>
                                </li>
                              </Link>
                            );
                          })
                      : "loading..!!"}
                    <Link to={`/HotelUrsa`}>
                      <li>
                        <a className="dropdown-item ">Hotel Ursa</a>
                      </li>
                    </Link>
                    <Link to={`/2025-season-seat`}>
                      <li>
                        <a className="dropdown-item ">2025 Season Seat</a>
                      </li>
                    </Link>
                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.toasttab.com/maine-savings-amphitheater-1-railroad-street/giftcards"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Gift Cards
                  </a>
                </li>
                <NavLink
                  className={`${
                    [`/Faq`].includes(pathname) ? `nav-bar-link` : ``
                  }`}
                  to={`/Faq`}
                >
                  <li className="nav-item">
                    <a className="nav-link">FAQ</a>
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="SocialIcons mobileViewHide extraHide">
              <a
                href="https://www.facebook.com/wfconcertsME"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>

              <a
                href="https://twitter.com/wfconcerts"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.youtube.com/user/wfconcerts"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.instagram.com/wfconcerts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.snapchat.com/add/wfconcerts"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-snapchat"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
