import React from "react";
import "./SeasonSeats2025.scss";
import seasonSeat2025logo from "../../Assets/Images/SeasonSeat2025/seasonSeat2025_Logo.png";
import seasonSeat2025_1 from "../../Assets/Images/SeasonSeat2025/seasonSeat2025_1.png";
import seasonSeat2025_2 from "../../Assets/Images/SeasonSeat2025/seasonSeat2025_2.png";
import seasonSeat2025_3 from "../../Assets/Images/SeasonSeat2025/seasonSeat2025_3.png";
import seasonSeat2025_4 from "../../Assets/Images/SeasonSeat2025/seasonSeat2025_4.png";
import seasonSeat2025_5 from "../../Assets/Images/SeasonSeat2025/seasonSeat2025_5.png";

export default function SeasonSeat2025() {
  return (
    <>
      <div className="season2025Section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="seasonBanner">
                <img src={seasonSeat2025logo} alt="" />
                <h1>2025 SEASON SEATS</h1>
                <h4>
                  Make the most of your entertainment dollars by treating
                  yourself, your friends, your colleagues, and business partners
                  to a unique experience at Maine Savings Amphitheater.
                </h4>
                <h3 className="seasonPackagesHead">
                  Full-season packages start at $5,400 per seat. To sign up
                  please
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSf5o3jg2zL8IbQbkvuP2KxRQOYkk_5MoXSwX4xB9GcxguIuVQ/viewform?usp=sf_link"
                    className="clrRed"
                    target="_blank"
                  >
                    &nbsp;click here
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="seatsDetails">
            <div className="availableHeading">
              <h1>AVAILABLE PACKAGES</h1>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <h1>PREMIUM BOX SEATS</h1>
                  <h4>
                    Enjoy in-seat service and complimentary snacks from the
                    comfort of our oversized seats in an environment that offers
                    a bit more "personal space".
                    <br />
                    This option includes parking, a designated "skip the line
                    entrance" and Premium Seat Club access, with a full, private
                    cash bar, and bathrooms.
                  </h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <img src={seasonSeat2025_1} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="seatsDetails">
            <div className="row flexColumbReverse">
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <img src={seasonSeat2025_2} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <h1>DUNKIN’ DECK</h1>
                  <a
                    target="_blank"
                    href=" https://my.matterport.com/show/?m=4SLwr5wJZER"
                    className="clrRed"
                  >
                    VIRTUAL TOUR
                  </a>
                  <h4>
                    The Dunkin' Deck at Maine Savings Amphitheater is a
                    standing-room area with prime viewing. It features a private
                    cash bar and restrooms, allowing for a comfortable and
                    exclusive experience. This option includes a designated
                    "skip the line entrance".
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="seatsDetails">
            <div className="row">
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <h1>MILLER LITE LANDING</h1>
                  <a
                    target="_blank"
                    href="https://my.matterport.com/show/?m=iEjFRFt7KiB"
                    className="clrRed"
                  >
                    VIRTUAL TOUR
                  </a>
                  <h4>
                    With a private, full cash bar, bar top tables, and private
                    bathrooms, the party deck offers an exceptional view of the
                    stage that is covered from the elements. This option
                    includes a designated "skip the line entrance".
                  </h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <img src={seasonSeat2025_3} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="seatsDetails">
            <div className="row flexColumbReverse">
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <img src={seasonSeat2025_4} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <h1>TRULY TERRACE</h1>
                  <a
                    target="_blank"
                    href="https://my.matterport.com/show/?m=cbTMQ7qLhe6"
                    className="clrRed"
                  >
                    VIRTUAL TOUR
                  </a>
                  <h4>
                    With a private, full cash bar, elevated lounge area, and
                    private bathrooms, the party deck offers an exceptional view
                    of the stage that is covered from the elements. This option
                    includes a designated "skip the line entrance".
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="seatsDetails">
            <div className="row">
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <h1>ROOFTOP SEATING</h1>
                  <h1 className="forMb">(NEW FOR 2025)</h1>
                  <h4>
                    Enjoy this "elevated" experience with sweeping views of the
                    venue. In-seat service, private bathrooms, designated
                    entrance, elevated, open-air.
                  </h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="seasonCardContent">
                  <img src={seasonSeat2025_5} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="seasonFooter">
        <h1>
          FOR MORE INFORMATION, CONTACT CHRIS RUDOLPH: <br /> 207.974.8570 |
          CHRIS@WATERFRONTCONCERTS.COM
        </h1>
      </div>
    </>
  );
}
