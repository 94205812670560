import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from '../../../Service/Instance';
import './season.scss';
import parse from 'html-react-parser';
import LoadingImage from '../../../Assets/Images/loading_Gif.gif';

export default function Season() {
  const [loading, setLoading] = useState<any>(false);
  const [subMenuData, setSubMenuData] = useState<any>([]);
  const { slug } = useParams();

  const ticketSubMenuData = async () => {
    try {
      setLoading(true);
      const response :any= await Axios.get(`/web/ticket-submenu?slug=${slug}`);
      setSubMenuData(response.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    ticketSubMenuData();
  }, [slug]);
  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="MainCommon">
        <div className="SeasonTicketsSection hiring">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="SeasonHead border-BottomClass">
                  <div className="SeasonHeading">
                    <h1> {subMenuData ? subMenuData.title : ''}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="SeasonTicketDetails boxStyle">
                  {subMenuData.content ? parse(subMenuData.content) : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
