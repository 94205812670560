import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./JobChild.scss";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Axios from "../../../Service/Instance";
import { ToastFailure, ToastSuccess } from "../../../Assets/Toaster/ToastMsg";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingImage from "../../../Assets/Images/loading_Gif.gif";
import axios from "axios";
import { getProviderIdFromUrl } from "../../../Assets/Common/Common";

export default function JobChild() {
  const [hearAboutUs] = useState<any>([
    { label: "Social media", value: "social media" },
    { label: "Career fair", value: "career fair" },
    { label: "Friends/family", value: "friends/family" },
    { label: "Newspaper", value: "newspaper" },
    { label: "Other", value: "other" },
  ]);
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [jobDiscription, setJobDiscription] = useState<any>("");
  const [uploadedFileName, setUploadedFileName] = useState<any>("");
  const [attachedFile, setAttachedFile] = useState<any>("");
  const [jobCateory, setJobCateory] = useState<any>("");
  const [jobQuestions, setJobQuestions] = useState<any>("");
  const [isFlag, setIsFlag] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState<any>(null);
  const { state }: any = useLocation();
  const _id: any = state?._id;
  const dateGenerator = () => {
    const d = new Date();
    return d.getFullYear();
  };
  const currentYear = dateGenerator();

  const navigate = useNavigate();

  useEffect(() => {
    if (_id) {
      getDetails(_id);
    }
  }, [_id]);

  const ApplyJobSchema = Yup.object().shape({
    name: Yup.string()
      .trim("The name cannot include leading and trailing spaces")
      .matches(/^[a-zA-Z\s]+$/, "Invalid characters in the name")
      .max(50, "Name must be less than 50 characters")
      .required("Required"),

    email: Yup.string()
      .email("Invalid email address")
      .trim("The mailing address cannot include leading and trailing spaces")
      .max(50, "Name must be less than 50 characters")
      .required("Email is required"),

    mailingAddress: Yup.string()
      .trim("The mailing address cannot include leading and trailing spaces")
      .max(50, "Name must be less than 50 characters")
      .required("Required"),

    aboveAgeOptions: Yup.string().required("Required"),

    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid phone number")
      .required("Required"),

    coverLetter: Yup.string()
      .trim("The cover letter cannot include leading and trailing spaces")
      .required("Required"),

    serviceExperience: Yup.string()
      .trim(
        "The customer service experience cannot include leading and trailing spaces"
      )
      .required("Required"),

    psmOptions: Yup.string().required("Required"),

    aboutUs: Yup.array().min(1).label("This"),
  });

  const saveJobAPI = async (values: any) => {
    const {
      name,
      email,
      mailingAddress,
      phoneNumber,
      aboveAgeOptions,
      coverLetter,
      serviceExperience,
      psmOptions,
      aboutUs,
    } = values;
    const obj = {
      jobId: _id,
      name,
      email,
      mailingAddress,
      isEighteen: aboveAgeOptions,
      phoneNumber,
      coverLatter: coverLetter,
      customerServiceExperience: serviceExperience,
      isWorkedWithPsm: psmOptions,
      attachment: attachedFile,
      hearAboutUs: aboutUs.length > 0 ? aboutUs.join() : "",
      recaptchaToken,
      answers: answerHandler(jobQuestions),
    };

    if (_id && _id != "") {
      if (recaptchaToken && recaptchaToken !== "") {
        await Axios.post(`web/apply-for-jobs`, obj, {
          headers: {
            Authorization: sessionStorage.getItem("AUTHTOKEN") || "",
          },
        })
          .then((response: any) => {
            if (response?.data?.status) {
              ToastSuccess(response?.data?.message);
              navigate(`/Job`);
            } else {
              ToastFailure(response.message ?? "Something went wrong.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        ToastFailure("Please select Recaptcha.");
        setIsFlag(false);
      }
    } else {
      ToastFailure("Please select job category first.");
      setIsFlag(false);
    }
  };

  const getDetails = async (_id: any) => {
    setLoading(true);
    await Axios.get(`web/jobsById`, {
      headers: {
        Authorization: sessionStorage.getItem("AUTHTOKEN") || "",
      },
      params: { _id },
    })
      .then((response: any) => {
        if (response?.data?.status) {
          setJobQuestions(
            jobsKeyHandler(
              response?.data?.data[0].jobQuestions
                ? response.data.data[0].jobQuestions
                : []
            )
          );
          setJobDiscription(response?.data?.data[0]?.description);
          setJobCateory(response?.data?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        ToastFailure(error.response.message);
        setLoading(false);
      });
  };

  const answerHandler = (data: any) => {
    return data.map((item: any) => ({
      questionId: item._id,
      jobId: item.jobId,
      answer: item.answer,
    }));
  };

  function convertToPlain(html: any) {
    // Create a new div element
    const tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  const acceptResumeFormat = (file: any) => {
    const format = [
      "application/pdf",
      "application/doc",
      "application/docx",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];
    return file?.type.includes("application/") && format.includes(file?.type);
  };

  const handleInput = (event: any) => {
    const fileData = [];
    for (let i = 0; i < event.target.files.length; i++) {
      fileData.push(event.target.files[i]);
    }
    setAttachedFile("");

    if (acceptResumeFormat(fileData[0])) {
      setIsFileUpload(true);
      uploadMultiPart(fileData);
    } else {
      ToastFailure("Error: Please upload a PDF, DOC, or DOCX file.");
      clearFileInputField();
    }
  };

  const uploadMultiPart = (File: any) => {
    setUploadedFileName(File[0].name);
    const percentagePerFile = [];
    const totalFileSize = 0;
    for (let i = 0; i < File.length; i++) {
      const perFileSize = (File[i]?.size * 100) / totalFileSize;
      percentagePerFile.push(perFileSize);
      uploadMedia(File[i], i, percentagePerFile, File.length);
    }
  };

  const uploadMedia = async (
    selectedFile: any,
    i: any,
    percentagePerFile: any,
    arrLength: any
  ) => {
    const extensionArr = selectedFile.name.split(".");
    const fileExtension = extensionArr[extensionArr.length - 1].toLowerCase(); //
    const contentTypeName = selectedFile.type;

    await Axios.post("start-upload", {
      extension: fileExtension,
      contentType: contentTypeName,
    })
      .then((response) => {
        if (response?.status == 200) {
          const uploadId = response?.data?.data?.UploadId;
          const fileName = response?.data?.data?.Key;

          const multipartData = {
            selectedFile: selectedFile,
            uploadId: uploadId,
            fileName: fileName,
            i: i,
            percentagePerFile: percentagePerFile,
            arrLength: arrLength,
          };
          uploadMultipartFile(multipartData);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setIsFileUpload(false);
        clearFileInputField();
      });
  };

  const getChunkSize = async (fileSize: any) => {
    let FILE_CHUNK = 5242880; // 3MB
    if (fileSize > 31457280) {
      //30 mb
      FILE_CHUNK = Math.floor(fileSize / 6);
    }
    return FILE_CHUNK;
  };

  /** method that upload in multipart **/
  const uploadMultipartFile = async (multipartData: any) => {
    const FILE_CHUNK_SIZE = await getChunkSize(multipartData.selectedFile.size);
    const fileSize = multipartData.selectedFile.size;

    let NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE);

    if (fileSize % FILE_CHUNK_SIZE > 0) {
      NUM_CHUNKS = NUM_CHUNKS + 1;
    }

    const promisesArray: any = [];
    let start, end, blob: any;

    for (let index = 1; index < NUM_CHUNKS + 1; index++) {
      start = (index - 1) * FILE_CHUNK_SIZE;
      end = index * FILE_CHUNK_SIZE;

      if (index == NUM_CHUNKS - 1) {
        const lastChunkSize = fileSize - end;
        if (lastChunkSize < 5242880) {
          // if chunksize is less than 5 MB then merge it in second last chunk
          NUM_CHUNKS = NUM_CHUNKS - 1;
        }
      }

      blob =
        index < NUM_CHUNKS
          ? multipartData.selectedFile.slice(start, end)
          : multipartData.selectedFile.slice(start);

      await Axios.post("get-presigned-url", {
        fileName: multipartData.fileName,
        partNumber: index,
        uploadId: multipartData.uploadId,
      })
        .then((response) => {
          if (response?.status == 200) {
            const signedUrl = response.data.data;

            const uploadResponse = new Promise(function (resolve) {
              axios
                .put(signedUrl, blob, {
                  headers: { "Content-Type": multipartData.selectedFile.type },
                })
                .then((res) => {
                  resolve(res);
                });
            });
            promisesArray.push(uploadResponse);
          }
        })
        .catch((error) => {
          console.log(error, "error");
          setIsFileUpload(false);
          clearFileInputField();
        });
    }

    const resolvedArray = await Promise.all(promisesArray);

    const uploadPartsArray: any = [];

    resolvedArray.forEach((resolvedPromise, index) => {
      console.log("resolvedPromise", resolvedPromise);
      uploadPartsArray.push({
        ETag: JSON.parse(resolvedPromise.headers.etag),
        PartNumber: index + 1,
      });
    });

    const data = {
      fileName: multipartData.fileName,
      parts: uploadPartsArray,
      uploadId: multipartData.uploadId,
    };

    await Axios.post("complete-upload", data)
      .then((response) => {
        if (response.status === 200) {
          setAttachedFile(getProviderIdFromUrl(response.data.data.Location));
          ToastSuccess("File uploaded successfully.");
          setIsFileUpload(false);
          clearFileInputField();
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setIsFileUpload(false);
        clearFileInputField();
      });
  };

  const onChangeHandler = (item: any, inputText: any) => {
    setJobQuestions((it: any) => {
      const newArr: any = it.map((ite: any) => {
        if (ite._id == item._id) {
          return { ...ite, answer: inputText };
        } else {
          return ite;
        }
      });
      return newArr;
    });
  };

  const jobsKeyHandler = (data: any) => {
    return data.map((item: any) => ({ ...item, answer: "" }));
  };

  const clearFileInputField = () => {
    const fileInput: any = document.getElementById("file");
    if (fileInput !== null) {
      fileInput.value = null;
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="loadingDiv">
            <img className="LoadingImage" src={LoadingImage} alt="" />
          </div>
        </>
      ) : (
        <>
          <div className="JobSection">
            <div className="container-fluid">
              <div className="JobBG">
                <div className="JobHead">
                  <div className="JobParaDiv">
                    <h3 className="JobChildHeading">
                      Join our team for the
                      <p> {`${currentYear}`}</p>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="JobDetailSection">
              <div className="container">
                <div className="row">
                  <div className="col align-self-center">
                    <div className="JobChildSec">
                      <div className="PositionSec boxStyle">
                        <div className="ChildSection">
                          <div className="PositionContent ">
                            <p className="JobChildCategory">
                              CATEGORY: &nbsp;
                              <b>
                                {jobCateory[0]?.jobCategory[0]?.categoryName}
                              </b>
                            </p>

                            <h3 className="JobChildHead">
                              {jobCateory !== ""
                                ? convertToPlain(jobCateory[0].title)
                                : ""}
                            </h3>
                            <p className="JobChildPara">
                              <p>Description: </p>
                              <p>
                                <b>
                                  {jobDiscription
                                    ? convertToPlain(jobDiscription)
                                    : ""}
                                </b>
                              </p>
                            </p>
                          </div>
                          <div className="JobChildForm">
                            <Formik
                              initialValues={{
                                name: "",
                                email: "",
                                mailingAddress: "",
                                phoneNumber: "",
                                coverLetter: "",
                                serviceExperience: "",
                                aboveAgeOptions: "",
                                psmOptions: "",
                                aboutUs: [],
                              }}
                              enableReinitialize={true}
                              validationSchema={ApplyJobSchema}
                              onSubmit={(values) => {
                                setIsFlag(true);
                                saveJobAPI(values);
                              }}
                              // validator={() => ({})}
                            >
                              {({
                                errors,
                                touched,
                                handleChange,
                                values,
                                setFieldValue,
                              }) => (
                                <Form>
                                  <div>
                                    <div className="mb-3 mt-5 ">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="exampleFormControlInput1"
                                        placeholder="Name"
                                        onChange={handleChange}
                                        value={values.name}
                                        maxLength={50}
                                      />
                                      {errors.name && touched.name ? (
                                        <div className="errorMsgs">
                                          {errors.name}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="mb-3 mt-3">
                                      <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Email"
                                        onChange={handleChange}
                                        value={values.email}
                                      />
                                      {errors.email && touched.email ? (
                                        <div className="errorMsgs">
                                          {errors.email}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="mb-4 mt-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Mailing address"
                                        name="mailingAddress"
                                        onChange={handleChange}
                                        value={values.mailingAddress}
                                        maxLength={255}
                                      />
                                      {errors.mailingAddress &&
                                      touched.mailingAddress ? (
                                        <div className="errorMsgs">
                                          {errors.mailingAddress}
                                        </div>
                                      ) : null}
                                    </div>
                                    <label className="mt-2" htmlFor="">
                                      Are you over the age of 18?
                                    </label>
                                    <br />
                                    <div className="form-check form-check-inline mt-3">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="aboveAgeOptions"
                                        id="inlineRadio1"
                                        onChange={handleChange}
                                        value="yes"
                                        defaultChecked={
                                          values.aboveAgeOptions === "yes"
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio1"
                                      >
                                        Yes
                                      </label>
                                    </div>

                                    <div className="form-check form-check-inline mt-3 mb-3">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="aboveAgeOptions"
                                        id="inlineRadio2"
                                        onChange={handleChange}
                                        value="no"
                                        defaultChecked={
                                          values.aboveAgeOptions === "no"
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio2"
                                      >
                                        No
                                      </label>
                                    </div>

                                    {errors.aboveAgeOptions &&
                                    touched.aboveAgeOptions ? (
                                      <div className="errorMsgs">
                                        {errors.aboveAgeOptions}
                                      </div>
                                    ) : null}

                                    <div className="mb-3 mt-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Phone number"
                                        name="phoneNumber"
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                      />
                                      {errors.phoneNumber &&
                                      touched.phoneNumber ? (
                                        <div className="errorMsgs">
                                          {errors.phoneNumber}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="mb-3 mt-3">
                                      <textarea
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows={6}
                                        placeholder="Cover letter"
                                        name="coverLetter"
                                        onChange={handleChange}
                                        value={values.coverLetter}
                                        maxLength={1000}
                                      ></textarea>
                                      {errors.coverLetter &&
                                      touched.coverLetter ? (
                                        <div className="errorMsgs">
                                          {errors.coverLetter}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="mb-3 mt-3">
                                      <textarea
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows={6}
                                        // Customer
                                        // service
                                        // experience
                                        placeholder="Customer service experience"
                                        name="serviceExperience"
                                        onChange={handleChange}
                                        value={values.serviceExperience}
                                        maxLength={1000}
                                      ></textarea>
                                      {errors.serviceExperience &&
                                      touched.serviceExperience ? (
                                        <div className="errorMsgs">
                                          {errors.serviceExperience}
                                        </div>
                                      ) : null}
                                    </div>

                                    <label htmlFor="" className="mt-3">
                                      Have you worked with PSM before?
                                    </label>
                                    <br />
                                    <div className="form-check form-check-inline mt-3">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="psmOptions"
                                        id="inlineRadio1"
                                        onChange={handleChange}
                                        value="yes"
                                        defaultChecked={
                                          values.psmOptions === "yes"
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio1"
                                      >
                                        Yes
                                      </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="psmOptions"
                                        id="inlineRadio2"
                                        onChange={handleChange}
                                        value="no"
                                        defaultChecked={
                                          values.psmOptions === "no"
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio2"
                                      >
                                        No
                                      </label>
                                    </div>

                                    {errors.psmOptions && touched.psmOptions ? (
                                      <div className="errorMsgs">
                                        {errors.psmOptions}
                                      </div>
                                    ) : null}

                                    <div className="mb-3 mt-3">
                                      <input
                                        id="file"
                                        type="file"
                                        accept=".pdf, .doc, .docx"
                                        onChange={(event) => {
                                          handleInput(event);
                                        }}
                                      />
                                      <label
                                        htmlFor="file"
                                        className="attachDiv"
                                      >
                                        <p>
                                          {attachedFile !== ""
                                            ? uploadedFileName
                                            : "Attach resume"}
                                        </p>
                                        <p>
                                          <i className="fa-regular fa-plus"></i>
                                        </p>
                                      </label>
                                      {isFileUpload !== null ? (isFileUpload ? <p>Resume uploading...</p> : (attachedFile ? <p>Resume uploaded successfully!</p>: <p>Resume upload fail please try again!</p>)) : <></>}
                                    </div>

                                    <label htmlFor="" className="mt-3">
                                      How did you hear about us?
                                    </label>
                                    {hearAboutUs.map(
                                      (list: any, index: any) => (
                                        <div
                                          className="form-check mt-3"
                                          key={index}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="aboutUs"
                                            value={list?.value}
                                            onChange={() => {
                                              const savedValue: any = [
                                                ...values.aboutUs,
                                              ];
                                              if (
                                                savedValue.indexOf(
                                                  list?.value
                                                ) != -1
                                              ) {
                                                const res = savedValue.filter(
                                                  (item: any) =>
                                                    item != list.value
                                                );
                                                setFieldValue("aboutUs", res);
                                              } else {
                                                savedValue.push(list.value);
                                                setFieldValue(
                                                  "aboutUs",
                                                  savedValue
                                                );
                                              }
                                            }}
                                            id="flexCheckDefault"
                                            checked={values.aboutUs.some(
                                              (item: any) => item == list.value
                                            )}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault"
                                          >
                                            {list.label}
                                          </label>
                                        </div>
                                      )
                                    )}

                                    {errors.aboutUs && touched.aboutUs ? (
                                      <div className="errorMsgs">
                                        {errors.aboutUs}
                                      </div>
                                    ) : null}
                                    <div className="mt-3">
                                      {jobQuestions
                                        ? jobQuestions.map(
                                            (item: any, index: any) => {
                                              return (
                                                <div key={index}>
                                                  <p
                                                    className="form-check-label QuesHeading"
                                                    // for="flexCheckDefault"
                                                  >
                                                    Question: {item.question}
                                                  </p>
                                                  <input
                                                    className="form-control mt-3"
                                                    type="text"
                                                    value={item.answer}
                                                    placeholder="Answer"
                                                    onChange={(e) =>
                                                      onChangeHandler(
                                                        item,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  {/* <button onClick={()=>handleAnswer(item)} className="btn-primary mt-4 mb-5">
                                                  Submit
                                                </button> */}
                                                </div>
                                              );
                                            }
                                          )
                                        : ""}
                                    </div>

                                    <div className="mt-3">
                                      <ReCAPTCHA
                                        sitekey="6LfTZisUAAAAAFMKRTa_nYA7Yy2ZXBYSa5SXEUjY"
                                        onChange={(value: any) =>
                                          setRecaptchaToken(value)
                                        }
                                      />
                                    </div>

                                    <div className="SubmitBtnForm">
                                      <button
                                        type="submit"
                                        className="btn-primary"
                                        disabled={isFlag || (isFileUpload !== null ? isFileUpload : false)}
                                      >
                                        SUBMIT
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
