import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./galleryPreviewModal.scss";

function GalleryPreviewModal(props: any) {
  const { show, modalHideHandler, images, imageIndex } = props;
  const [imageUrl, setImageUrl] = useState<any>(images[imageIndex]);
  const [imageModalIndex, setImageModalIndex] = useState<any>(imageIndex);


  const previousHandleClick = () => {
    if(imageModalIndex === 0){
        return;
    } 
    const index = imageModalIndex;
    setImageModalIndex(index -1)
    setImageUrl(images[index - 1]);
  };

  const nextHandleClick = () => {
    if(imageModalIndex === images.length - 1){
        return;
    } 
    const index = imageModalIndex;
    setImageModalIndex(index + 1)
    setImageUrl(images[index + 1]);
  };

  return (
    <>
      <Modal
        className="imagePreviewSec"
        show={show}
        onHide={() => modalHideHandler(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="slideBtnSec">
            <div className="prevBtn">
              <a>
                <button onClick={previousHandleClick}> &lt; Prev</button>
              </a>
            </div>
            <div className="openBtn">
              <a href={imageUrl} target="_blank">
                <button>Open image in new tab</button>
              </a>
            </div>
            <div className="nextBtn">
              <a>
                <button onClick={nextHandleClick}>Next &gt;</button>
              </a>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <img src={imageUrl} alt="" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GalleryPreviewModal;
