import React, { useEffect, useState } from "react";
import "../EventsPage/event.scss";
import moment from "moment";
import Featured from "../Featured/Featured";
import Carousel from "react-bootstrap/Carousel";
import CompanyNames from "../CompanyNames/CompanyNames";
import SkyLineImage from "../../Assets/Images/blueTop.png";
import GreyLineImage from "../../Assets/Images/gray_tunda_bg.png";
import PinkCut from "../../Assets/Images/pink_cut-Image.png";
import Axios from "../../Service/Instance";
import LoadingImage from "../../Assets/Images/loading_Gif.gif";
import defaultBannerImg from "../../Assets/Images/carousel-4.jpg";

const EventPage = () => {
  const [item, setItem] = useState<any>([]);
  const [venueData, setVenueData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  const [getTitle, setGetTitle] = useState();

  const ApiCalling = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(`banners`);
      setItem(response.data.data.banners);
      setGetTitle(response.data.data.sectionTitle);
      setLoading(false);
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    }
  };

  const VenueApiCall = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(`venues`);

      const venueFeatured = response.data.data.filter((e: any) => {
        return e.isFeatured;
      });
      setVenueData(venueFeatured);
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    ApiCalling();
    VenueApiCall();
  }, []);

  if (loading) {
    return (
      <>
        <div className="loadingDiv">
          <img className="LoadingImage" src={LoadingImage} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="Slider">
        {item.length > 0 ? (
          <Carousel
            interval={3000}
            data-interval="false"
            indicators={item.length > 1 ? true : false}
            controls={item.length > 1 ? true : false}
          >
            {item.length > 0 &&
              item.map((e: any, index: any) => {
                return (
                  <Carousel.Item key={index}>
                    <a target="_blank" rel="noreferrer" href={e.buttonLink}>
                      <img
                        className="d-block w-100"
                        src={e.image}
                        alt="First slide"
                      />
                      <Carousel.Caption>
                        {e?.eventId ? (
                          <div className="NewShowSec">
                            <div key={index} className="NewShowDetail">
                              <div className="NewShowHead">
                                <div className="NewShowHeading">
                                  <h5>NEW SHOW </h5>
                                  <span>
                                    <img src={PinkCut} alt="" />
                                  </span>
                                </div>
                                <div className="NewShowDate">
                                  <h5>
                                    {e &&
                                      moment(e.eventStartDateTime).format("MMM")}
                                  </h5>
                                  <h6>
                                    {e && moment(e.eventStartDateTime).format("DD")}
                                  </h6>
                                </div>
                              </div>
                              <div className="NewShowTitle">
                                <h6>{e.title}</h6>
                                <h2>{e.bannerText}</h2>
                              </div>
                              <div className="NewShowLocation">
                                <p>
                                  <span>
                                    <i className="fa-solid fa-location-dot"></i>
                                  </span>
                                  {e.venue}
                                </p>
                              </div>
                            </div>
                            <div className="GreyImageSec">
                              <img src={GreyLineImage} alt="" />
                            </div>
                            <div className="SkyImageSec">
                              <img src={SkyLineImage} alt="" />
                            </div>
                            <a
                              href={e.buttonLink}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <div className="NewShowBuy">
                                <button type="button" className="btn-primary">
                                  {!e.eventOnSaleDateTime
                                    ? "TBA"
                                    : e.isOnsale
                                    ? "Buy Now"
                                    : `On sale - ${moment(
                                        e.eventOnSaleDateTime
                                      ).format("MMM D, y")}`}
                                </button>
                              </div>
                            </a>
                          </div>
                        ) : (
                          <div className="NoEventShowSec">
                            <div key={index} className="NewShowDetail">
                              <div className="NewShowHead">
                                <div className="NewShowHeading">
                                  <h5 className="bannerTitle">
                                    {e ? e.title : ""}
                                  </h5>
                                </div>
                              </div>
                              <div className="NewShowTitle">
                                <h6>{e.bannerText}</h6>
                              </div>
                              <a
                                href={e.buttonLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="NewShowBuy">
                                  <button type="button" className="btn-primary">
                                    {e.buttonText}
                                  </button>
                                </div>
                              </a>
                            </div>
                          </div>
                        )}
                      </Carousel.Caption>
                    </a>
                  </Carousel.Item>
                );
              })}
          </Carousel>
        ) : (
          <>
            <img className="defaultBannerImg" src={defaultBannerImg} alt="" />
          </>
        )}
      </div>
      <div className="container">
        <div className="row">
          <div className="companySec mobileViewHide">
            {venueData.length > 0 &&
              venueData.slice(0, 6).map((data: any) => {
                return (
                  <CompanyNames
                    key={data._id}
                    img={data.featureImage}
                    id={data._id}
                    slug={data.slug}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <Featured getTitles={getTitle} />
    </>
  );
};

export default EventPage;
