import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../TextError';

function InputWithLabel(props:any) {
  const { label, name, containerStyle, labelStyle, fieldStyle, ...rest } =
    props;
  return (
    <div className={containerStyle}>
      <label htmlFor={name} className={labelStyle}>
        {label}
      </label>
      <Field id={name} name={name} className={fieldStyle} {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default InputWithLabel;
