import { Modal } from "react-bootstrap";
import React from "react";

const IframeModel = (props: any) => {
  const { link, height, width, modelShow, setModelShow } = props;
  return (
    <Modal show={modelShow} onHide={() => setModelShow(false)} animation={true}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
      <iframe src={link} title="Event Updates" className="event-update-iframe"></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default IframeModel;
