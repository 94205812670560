import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Contact from "../Components/ContactPage/Contact";
import EventChlid from "../Components/EventsPage/Event Chlid page/EventChlid";
import EventPage from "../Components/EventsPage/EventPage";
import Faq from "../Components/FAQPage/Faq";
import General from "../Components/FAQPage/FAQ-Components/General";
import Footer from "../Components/Footer/Footer";
import GuestService from "../Components/GuestServices/GuestServices";
import Job from "../Components/JobPage/Job";
import JobChild from "../Components/JobPage/JobChild/JobChild";
import Navbar from "../Components/Navbar/Navbar";
import Packages from "../Components/Packages/Packages";
import Season from "../Components/Tickets/SeasonPage/Season";
import Venue from "../Components/VenuePage/Venue";
import VenueCardDetails from "../Components/VenuePage/VenueCard/VenueCardDetail/VenueCardDetails";
import useSocketService from "../Service/useSocketService";
import Booking from "../Components/Booking/Booking";
import SeasonSeat2025 from "../Components/SeasonSeats2025/SeasonSeat2025";

function RouteHandler() {
  const socketMessage = useSocketService();
  return (
    <div className="MAIN">
      <Navbar />
      <Routes>
        <Route path="/" element={<EventPage />}></Route>
        <Route path="/Venue" element={<Venue />} />
        <Route
          path="Venue/VenueCardDetails/:id"
          element={<VenueCardDetails />}
        />
        <Route path="/Job" element={<Job />}></Route>
        <Route path="/JobChild" element={<JobChild />} />
        <Route path="/GuestService" element={<GuestService />} />
        <Route path="/Faq" element={<Faq />}>
          <Route path=":questionId" element={<General />} />
        </Route>
        <Route path="/Season/:slug" element={<Season />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Package" element={<Packages />} />
        <Route path="/EventChild/:id" element={<EventChlid />} />
        <Route path="/HotelUrsa" element={<Booking />} />
        <Route path="/2025-season-seat" element={<SeasonSeat2025 />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default RouteHandler;
