import React from 'react';
import './featured.scss';
export default function Icons(props:any) {
  return (
    <>
      <div className="GridIconSec">
        <a>
          <span
            className={props.isActive ? 'LightGridIcon1' : 'gridicon1'}
            onClick={props.handleClick}
          ></span>
        </a>
        <a className="gridIconVenue VenueGridIcon">
          <span
            className={props.isActive ? 'LightGridIcon2' : 'gridicon2'}
            onClick={props.handleClick}
          ></span>
        </a>
      </div>
    </>
  );
}
