/**
 * prepare array into value and label type object
 * @param {Array} list
 * @returns
 */
export const prePareVanueList = (list:any) => {
  const updatedList = list.map((val:any) => {
    return {
      value: val._id,
      label: val.title,
    };
  });
  return updatedList;
};

/**
 * prepare array into value and label type object
 * @param {Array} list
 * @returns
 */
export const prePareCityList = (list:any) => {
  const updatedList = list.map((val:any) => {
    return {
      value: val._id,
      label: val.name,
    };
  });
  return updatedList;
};
