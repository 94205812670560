import Modal from "react-bootstrap/Modal";
import "./imagePreviewModal.scss";

function ImagePreviewModal(props: any) {
  const { show, modalHideHandler, imageUrl } = props;
  return (
    <>
      <Modal
        className="imagePreviewSec"
        show={show}
        onHide={() => modalHideHandler(false)}
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={imageUrl} alt="" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImagePreviewModal;
