import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../TextError';

function CheckBoxGroupWithLabel(props:any) {
  const {
    label,
    name,
    options,
    containerStyle,
    labelStyle,
    fieldStyle,
    ...rest
  } = props;
  return (
    <div className={containerStyle}>
      <label htmlFor={label} className={labelStyle}>{label}</label>
      <Field name={name} className={fieldStyle}>
        {({ field }:any) => {
          return options.map((option:any) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type="checkbox"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default CheckBoxGroupWithLabel;
