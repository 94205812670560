import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError";
import Select from "react-select";
function SelectBox(props: any) {
  const { options, containerStyle, fieldStyle, errorMsgStyle, name, ...rest } =
    props;

  return (
    <div className={containerStyle}>
      <Field name={name} className={fieldStyle}>
        {({ form, field }: any) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Select
              id={name}
              {...rest}
              options={options}
              value={value}
              onChange={(val) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      <ErrorMessage
        component={`${(<TextError errorMsgStyle={errorMsgStyle} />)}`}
        name={name}
      />
    </div>
  );
}

export default SelectBox;
