import React from "react";
import { useNavigate } from "react-router-dom";
import "./venueCard.scss";

export default function VenueCard(props: any) {
  const { title, image, address, id, slug } = props;
  const navigate = useNavigate();
  const onNavigate = (_id: any) => {
    navigate(`/Venue/VenueCardDetails/${slug}`, { state: { id: _id } });
  };
  return (
    <>
      <div className="col-sm-6 col-md-4 col-lg-3">
        <div className="VenueShowsCard">
          <div className="VenueCardSection">
            <div className="VenueCard">
              <div className="card">
                <div className="CardImage">
                  <img src={image} className="card-img-top" alt="..." />
                </div>
                <hr />

                <div className="card-body">
                  <div className="VenueCardDetail">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{address}</p>
                  </div>
                </div>
                <div className="card-footer">
                  <a
                    className="btn btn-primary MoreInfoBtn"
                    onClick={() => onNavigate(id)}
                  >
                    MORE INFO
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
