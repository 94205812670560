import React, { useEffect, useState } from "react";
import Axios from "../../Service/Instance";
import "./package.scss";
import parse from "html-react-parser";

export default function Packages() {
  const [packageItem, setPackageItem] = useState<any>([
    {
      _id: "",
      title: "",
      venue: "",
    },
  ]);
  const [packageList, setPackageList] = useState<any>([]);
  const [activeDropDownValue, setActiveDropDownValue] = useState<any>("");
  const [headingToDisplay, setHeadingToDisplay] = useState<any>("All");

  const dateGenerator = () => {
    const d = new Date();
    return d.getFullYear();
  };
  const currentYear = dateGenerator();

  const handleDropDownChanges = async (id: any) => {
    const params: any = {
      eventId: id,
    };

    try {
      const res = await Axios.get(`package-by-event`, { params: params });
      const resArray = res.data.data || [];
      const packageByEventIdList = resArray.map((item: any) => {
        return {
          title: item.title,
          description: item.description,
          ticketLink: item.ticketLink,
          eventName: "",
        };
      });
      setPackageList(packageByEventIdList);
    } catch (error) {
      console.log("error", error);
    }
  };

  const eventForPackage = async () => {
    try {
      const ResponsePackageApi = await Axios.get("events?eventType=UPCOMING");

      const resArray = ResponsePackageApi.data.data || [];

      const eventArray = resArray.map((e: any) => {
        return {
          _id: e._id,
          title: e.title,
          venue: e.venue ? e.venue.title : "",
        };
      });

      setPackageItem(eventArray);

      const allPackageByEvent: any = {};

      resArray.forEach((element: any) => {
        if (element.packages && element.packages.length > 0) {
          allPackageByEvent[element._id] = element.packages.map((item: any) => {
            return {
              title: item.title,
              description: item.description,
              ticketLink: "",
              eventName: element.title + " - " + element.venue.title,
            };
          });
        }
      });

      let allPackeages: any = [];

      for (const key in allPackageByEvent) {
        if (Array.isArray(allPackageByEvent[key])) {
          allPackeages = [...allPackeages, ...allPackageByEvent[key]];
        }
      }

      setPackageList(allPackeages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    eventForPackage();
  }, []);

  return (
    <div className="PackageSection">
      <div className="container-fluid">
        <div className="PackageBG">
          <div className="PackageHead">
            <h1 className="PackageHeading">Packages</h1>
            <h3>{`PACKAGES AVAILABLE FOR ${currentYear} SHOWS`}</h3>
            <select
              className="form-select PackageDropdown"
              aria-label="Default select example"
              onChange={(e) => {
                setActiveDropDownValue(e.target.value);
                if (e.target.value) {
                  const heading = packageItem.find((item: any) => {
                    return item._id === e.target.value;
                  });
                  setHeadingToDisplay(heading.title + " - " + heading.venue);
                  handleDropDownChanges(e.target.value);
                } else {
                  setHeadingToDisplay("All");
                  eventForPackage();
                }
              }}
              value={activeDropDownValue}
            >
              <option value="">All</option>
              {packageItem.map((item: any, index: any) => {
                return (
                  <option value={`${item?._id}`} key={index}>
                    {item?.title + " - " + item?.venue}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="PackageDetailsSec">
        <div className="container">
          <div className="row">
            <div className="border-BottomClass">
              <h1>{headingToDisplay}</h1>
            </div>
          </div>

          {packageList.length > 0 ? (
            packageList.map((item: any, index: any) => {
              return (
                <div className="row" key={index}>
                  <div className="boxStyle">
                    <h2>
                      {item.title} {item.eventName ? `(${item.eventName})` : ""}
                    </h2>
                    {console.log("item", item.description)}
                    <p className="description">
                      {item.description ? parse(item.description) : ""}
                    </p>
                    {activeDropDownValue ? (
                      item.ticketLink && (
                        <a href={item.ticketLink} target="_blank">
                          <button className="btn-primary PackageBtn">
                            Book Package
                          </button>
                        </a>
                      )
                    ) : (
                      <p>
                        <b>
                          Please select your event above from the dropdown to
                          book packages for that event
                        </b>
                      </p>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="dataNotFound">
              <p className="NoFoundPara">No Packages found.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
