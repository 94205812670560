import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import headerBlueLogo from "../../Assets/Images/headerL.png";
import "../event-child-model/eventChildModal.scss";
function EventChildModel(props: any) {
  const { show, setShow, discription, title } = props;
  return (
    <>
      <Modal
        size="lg"
        className=" EventChildModal"
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <div className="EventChildModalBody">
          <img className="ModalPackageIcon" alt="" src={headerBlueLogo} />
          <h1 className="ModalHeadingEvent">{title}</h1>
        </div>

        <Modal.Body>
          <p className="discription">{discription}</p>
        </Modal.Body>
        <div className="EventChildModalBtn">
          <Button className="btn-primary" onClick={() => setShow(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default EventChildModel;
